<template>
  <Dialog
      :breakpoints="{'1190px': '75vw', '640px': '100vw'}" :style="{width: '55vw'}"
      class="view p-datatable-gridlines p-datatable-striped"
      position="top"
      :maximizable="true"
      v-model:visible="computedVisible"
      :modal="true"
      :closable="true">

    <div id="overlay" v-if="loading1">
      <div class="center">
        <ProgressSpinner
            style="width:450px;height:450px;display: flex; justify-content: center !important;"
            strokeWidth="2"/>
      </div>
    </div>

    <template #header>
      <div class="ticket-header">
        {{ ticket.rows[0].tticket }}
      </div>
    </template>
    <template #footer>
      <div class="container-condensed" v-if="ticket.rows[0].tdireccion">
        <div class="title">
          Dirección:
        </div>
        <div class="content">
          <td v-html="ticket.rows[0].tdireccion"></td>
        </div>
      </div>
      <h1 v-if="page==='cancel' && this.currentStatus !== 'ANULADO'">
        <Button
            class="t-tickets p-button-danger"
            type="button"
            icon="pi pi-times"
            label="ANULAR TICKET"
            @click="openViewTicketDialog(ticket.rows[0])"
        />
      </h1>
    </template>

    <div class="p-fluid grid">
      <div class="field col-12 md:col-6">
        <Label2 title="Agencia:" :content="ticket.rows[0].tagencia " :condensed="true"/>
        <Label2 title="Número de ticket:" :content="ticket.rows[0].tticket" :condensed="true"/>
        <Label2 title="Fecha:" :content="formatDateLong2(ticket.rows[0].dtransaccion)" :condensed="true"/>
        <Label2 title="Hora:" :content="ticket.rows[0].hora" :condensed="true"/>
      </div>
      <div class="field col-12 md:col-6">
        <div class="container-condensed">
          <div class="title">Estatuus</div>
          <div :class="statusClass(ticket.rows[0], {tstatus: this.currentStatus})">
              {{ getStatus(ticket.rows[0]) }}
          </div>
        </div>
        <Label2 title="Premio:" :content="formatCurrency(ticket.premio)" :condensed="true"/>
        <Label2 title="Estado:" :content="ticket.rows[0].testado" :condensed="true"/>
        <Label2 title="Municipio:" :content="ticket.rows[0].tmunicipio" :condensed="true"/>
      </div>
    </div>

    <DataTable
        :value="ticket.rows"
        responsiveLayout="scroll"
        scrollHeight="700px"
        :scrollable="true"
        scrollDirection="both"
        dataKey="row_number"
        class="p-datatable-gridlines p-datatable-sm p-datatable-striped">

      <Column field="row_number" header="Id" style="width:30px !important; text-align: right !important;">
        <template #body="{data}">
          <div :class="ticketPrizeClass(data)">{{ data.row_number }}</div>
        </template>
      </Column>

      <Column field="tproductoxsorteo" header="Sorteo" style="width:220px !important; text-align: right !important;">
        <template #body="{data}">
          <div :class="ticketPrizeClass(data)" style="font-size: smaller;">{{ data.tproductoxsorteo }}</div>
        </template>
      </Column>

      <Column field="nnumero" header="Número"
              style="width:90px; display: flex;justify-content: center;"
              :sortable="true"
              bodyStyle="text-align: center;"
              v-if="!ticket.rows[0].animal">
        <template #body="{data}">
          <div :class="ticketPrizeClass(data)" v-if="data.nnumero && data.id_lista === 0">
            {{ data.nnumero }}
          </div>
          <div :class="ticketPrizeClass(data)" v-if="data.nnumero && data.id_lista === 1">
            {{ data.nnumero }}
          </div>
          <div :class="ticketPrizeClass(data)" v-if="data.nnumero && data.id_lista === 2">
            {{ data.nnumero.substr(0, 2) + '-' + getSign(data.nnumero.substr(2, 1)) }}
          </div>
          <div :class="ticketPrizeClass(data)" v-if="data.nnumero && data.id_lista === 3">
            {{ data.nnumero.substr(0, 3) + '-' + getSign(data.nnumero.substr(3, 1)) }}
          </div>

        </template>
      </Column>

      <Column field="animal" header="Animalito"
              style="width:280px;display: flex; justify-content: center;"
              bodyStyle="text-align: center"
              footer="Total:" v-if="ticket.rows[0].animal">

        <template #body="{data}">
          <div :class="ticketPrizeClass(data)">{{ data.animal }}</div>
        </template>
      </Column>

      <Column field="tlista"
              header="Lista" v-if="!ticket.rows[0].animal"
              style="width:100px; display: flex;justify-content: center;"
      >
        <template #body="{data}">
          <div :class="ticketPrizeClass(data)" style="font-size: smaller;">{{ data.tlista }}</div>
        </template>
      </Column>
      <Column field="ttipo_lista"
              tyle="width:100px; display: flex;justify-content: center;"
              header="Tipo" v-if="!ticket.rows[0].animal"
              style="width:100px; display: flex;justify-content: center;">
        <template #body="{data}">
          <div :class="ticketPrizeClass(data)" style="font-size: smaller;">{{ data.ttipo_lista }}</div>
        </template>
      </Column>

      <Column field="mmonto_aceptado" header="Monto"
              style="width:130px; display: flex;justify-content: end;"
              :footer="formatCurrency(ticket.venta)">
        <template #body="{data}">
          <div :class="ticketPrizeClass(data)">{{ formatCurrency(data.mmonto_aceptado) }}</div>
        </template>
      </Column>
      <Column field="mmonto_premio" header="Premio" :sortable="true"
              :footer="formatCurrency(ticket.premio)"
              style="width:130px; display: flex;justify-content: end;">
        <template #body="{data}">
          <div :class="ticketPrizeClass(data)">
            {{ formatCurrency(data.mmonto_premio) }}
          </div>
        </template>
      </Column>

      <Column field="tcodigo_validacion" header="Código de validación" :sortable="true"
              style="width:230px;">
        <template #body="{data}">
          <div :class="ticketPrizeClass(data)">
            {{ data.tcodigo_validacion }}
          </div>
        </template>
      </Column>

    </DataTable>
  </Dialog>
  <ConfirmDialog
      :show="displayConfirmation"
      :message="message"
      @confirm-action="cancelTicket"
      @close="closeViewTicketDialog"/>

  <Toast/>

</template>

<script>
import TicketService from "@/service/backend/TicketService";
import MainService from "@/service/backend/MainService";
import ProgressSpinner from 'primevue/progressspinner';
import generalMixin from "@/mixins/generalMixin";
import Label2 from "@/components/misc/Labels/Label2";
import ConfirmDialog from "@/components/misc/Confirm/ConfirmDialog";



export default {
  name: "ViewDialog",
  components: {
    Label2,
    ConfirmDialog,
    ProgressSpinner
  },
  props: {
    show: Boolean,
    ticket: Object,
    lista: String,
    page: String,
  },
  mixins: [generalMixin],
  data() {
    return {
      params: null,
      header: null,
      particular: null,
      total: null,
      displayConfirmation: false,
      message: null,
      selectedTicket: null,
      loading1: false,
      currentStatus: null,
    }
  },
  created() {
    this.ticketService = new TicketService;
    this.mainService = new MainService;
  },
  updated() {
    if (this.ticket)
      this.currentStatus = this.ticket.rows && this.ticket.rows[0].tstatus;
  },
  methods: {
    closeViewTicketDialog() {
      this.displayConfirmation = false;
    },
    openViewTicketDialog(params) {
      this.selectedTicket = params;
      this.message = `Esta operación es irreversible.<br><br> ¿Anular ticket número: <strong>${params.tticket}</strong>, agencia: <strong>${params.tagencia}</strong>?`;
      this.displayConfirmation = true;
    },
    async cancelTicket() {
      this.loading1 = true;
      const ipData = await this.mainService.getClientsIp();

      const response = await this.ticketService.cancelTicket({
        id_transaccion: this.selectedTicket.id_transaccion,
        ip: ipData.ip,
      });

      if (response[0] && response[0].fn_anula_tickets === 0) {
        this.$toast.add({
          severity: 'success',
          summary: 'Ticket anulado',
          detail: `Ticket número ${this.selectedTicket.tticket} ha sido anulado | Agencia: ${this.selectedTicket.tagencia}`,
          life: 10000
        });
        this.currentStatus = 'ANULADO';
      } else if (response.error) {
        this.$toast.add({
          severity: 'error',
          summary: 'Ticket no anulado',
          detail: `Ticket número ${this.selectedTicket.tticket} no pudo ser anulado | ` + response.error,
          life: 30000
        });
      } else {
        this.$toast.add({
          severity: 'error',
          summary: 'Ticket no anulado',
          detail: `Ticket número ${this.selectedTicket.tticket} no pudo ser anulado | Por favor refiérase a la consola para determinar el error`
        });
      }
      this.loading1 = false;
    }
  },
  computed: {
    computedVisible: {
      get() {
        return this.show
      },
      set() {
        this.$emit('close')
      }
    },
  }
}
</script>

<style scoped lang="scss">

.p-button .p-component {
  min-width: 200px !important;
}

.record {
  display: flex;
  justify-content: space-between;
}

.label {
  font-weight: bold;
}

.view {
  font-size: xx-small !important;
}

.winner {
  color: #FFA726;
  font-weight: bold;
}

.ticket-header {
  background-color: #004a49;
  padding: 5px 15px 5px 15px;
  border-radius: 15px;
  color: white;
}


.container-condensed {
  display: flex;
  /*padding: 10px;*/
  border: solid 1px var(--primary-color2);
  margin: 5px 0 5px 0;
  border-radius: 5px;
  height: auto;

  .title {
    margin-right: 10px;
    background-color: var(--primary-color2);
    color: white;
    padding: 1px 1px 1px 10px;
    min-width: 150px;
    font-size: small;
  }

  .content {
    padding: 2px 10px 2px 2px;
    min-width: 70px;
    font-size: xx-small;
  }
}

.t-tickets {
  width: 100% !important;
}

</style>