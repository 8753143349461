<template>

  <div v-if="!titleStyle">

    <div class="container" v-if="!condensed">
      <div class="title" v-if="!justify">{{ title }}</div>
      <div class="title-justified" v-if="justify">{{ title }}</div>
      <div :class="'content '+labelClass">
        {{ content ? content : '&nbsp;' }}
      </div>
    </div>

    <div class="container-condensed" v-if="condensed">
      <div class="title" v-if="!justify">{{ title }}</div>
      <div class="title-justified" v-if="justify">{{ title }}</div>
      <div :class="'content '+labelClass">
        {{ content ? content : '&nbsp;' }}
      </div>
    </div>

  </div>
  <div v-else-if="titleStyle">

    <div class="container">
      <div class="title" :style="titleStyle">{{ title }}</div>
      <div :class="'content '+labelClass">
        {{ content ? content : '&nbsp;' }}
      </div>
    </div>

  </div>

</template>

<script>
export default {
  name: "Label2",
  props: {
    title: String,
    content: String,
    labelClass: String,
    condensed: Boolean,
    justify: Boolean,
    titleStyle: String,
  }
}
</script>

<style scoped lang="scss">

.container {
  display: flex;
  /*padding: 10px;*/
  border: solid 1px var(--primary-color2);
  margin: 5px 0 5px 0;
  border-radius: 5px;

  .title {
    margin-right: 10px;
    background-color: var(--primary-color2);
    color: white;
    padding: 10px 10px 10px 15px;
    min-width: 100px;
  }

  .title-justified {
    margin-right: 10px;
    background-color: var(--primary-color2);
    color: white;
    padding: 10px 15px 10px 15px;
  }

  .content {
    padding: 10px;
    min-width: 70px;
  }
}

.container-condensed {
  display: flex;
  /*padding: 10px;*/
  border: solid 1px var(--primary-color2);
  margin: 5px 0 5px 0;
  border-radius: 5px;
  height: auto;

  .title {
    margin-right: 10px;
    background-color: var(--primary-color2);
    color: white;
    padding: 1px 1px 1px 10px;
    min-width: 150px;
    font-size: small;
  }

  .title-justified {
    margin-right: 10px;
    background-color: var(--primary-color2);
    color: white;
    padding: 1px 10px 1px 10px;
    font-size: small;
  }

  .content {
    padding: 1px 10px 1px 1px;
    min-width: 70px;
    font-size: small;
  }
}


</style>